// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js

import { useEffect } from "preact/hooks";
import { useRouter } from "next/router";
import * as gtag from "../common/gtag";
import { initDatadog } from "../common/datadog";
// import "semantic-ui-css/components/message.min.css";
import "semantic-ui-css/components/message.min.css";
import "semantic-ui-css/components/dimmer.min.css";
import "semantic-ui-css/components/modal.min.css";
import "semantic-ui-css/components/header.min.css";
import "semantic-ui-css/components/button.min.css";
import "semantic-ui-css/components/checkbox.min.css";

// Semantic UI
import "semantic-ui-css/components/icon.min.css";

import "../styles/globals.scss";

initDatadog();

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return <Component {...pageProps} />;
};

export default App;
