import { datadogRum } from "@datadog/browser-rum";

export const initDatadog = () => {
  if (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
    if (
      process.browser &&
      process.NODE_ENV !== "development" &&
      window.location.hostname !== "localhost"
    ) {
      // Based on sentry plugin
      // https://github.com/vercel/next.js/blob/canary/examples/with-sentry/next.config.js
      const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "1.0.1";

      datadogRum.init({
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        site: "datadoghq.com",
        service: "nyc-vaccine-list-frontend",
        //  env: 'production',
        version, // is there a way to auto increment / read from git hash?
        sampleRate: 100,
        trackInteractions: true,
      });
    }
  }
};
